// @ts-nocheck
import styled, { css } from 'styled-components';
import { color as clr, fontFamily as ff, fontSize as fs, spacer } from 'theme';
import { TypographyProps } from './Typography';

const typoMapper = {
  h1: {
    fontFamily: ff('secondary'),
    fontSize: 175,
    fontWeight: 'bold',
    marginBottom: 100,
  },
  h2: {
    fontFamily: ff('secondary'),
    fontSize: 150,
    fontWeight: 'bold',
    marginBottom: 100,
  },
  h3: {
    fontFamily: ff('secondary'),
    fontSize: 125,
    fontWeight: 'bold',
    marginBottom: 100,
  },
  h4: {
    fontFamily: ff('secondary'),
    fontSize: 100,
    fontWeight: 'bold',
    marginBottom: 100,
  },
  p: {
    fontFamily: ff('secondary'),
    fontSize: 100,
    fontWeight: 'normal',
    marginBottom: 100,
  },
  span: {
    fontSize: 100,
    fontWeight: 'normal',
    marginBottom: 100,
  },
};

export const StyledTypography = styled.span<TypographyProps>`
  color: ${({ color }) => clr(color)};
  font-family: ${({ fontFamily, type }) => ff(fontFamily || typoMapper[type]?.fontFamily)};
  font-size: ${({ fontSize, type }) => fs(fontSize || typoMapper[type]?.fontSize)};
  font-weight: ${({ fontWeight, type }) => fontWeight || typoMapper[type]?.fontWeight};

  margin-bottom: ${({ marginBottom, type }) =>
    marginBottom === 0 ? '0px' : spacer(marginBottom || typoMapper[type]?.marginBottom)};
  margin-top: ${({ marginTop }) => (marginTop === 0 ? '0px' : spacer(marginTop))};

  text-align: ${({ textAlign }) => textAlign};
  text-decoration: ${({ textDecoration }) => textDecoration};
  text-transform: ${({ textTransform }) => textTransform};
  line-height: ${({ lineHeight }) => (lineHeight === 0 ? '0px' : spacer(lineHeight))};
  white-space: ${({ whiteSpace }) => whiteSpace};
  word-break: ${({ wordBreak }) => wordBreak};

  ${({ maxLines }) =>
    !!maxLines &&
    css`
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: ${maxLines};
      -webkit-box-orient: vertical;
    `}
`;
