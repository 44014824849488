/* eslint-disable sort-keys-fix/sort-keys-fix */
import Icon from 'components/Icon/Icon';
import styled, { RuleSet, css } from 'styled-components';
import { color, spacer, specific, variable } from 'theme';
import { ButtonColor, ButtonSize, FontWeight, IconPosition, NegativeMargin } from '../../constants/styling';
import { isNumericValue } from '../../utils/textUtil';

export const buttonSizes: Record<string, RuleSet<object>> = {
  icon: css`
    padding: ${spacer(75)} ${spacer(75)};
  `,
  small: css`
    padding: ${spacer(25)} ${spacer(125)};
  `,
  medium: css`
    padding: ${spacer(50)} ${spacer(125)};
  `,
  large: css`
    padding: ${spacer(75)} ${spacer(125)};
  `,
};

const buttonColors: Record<ButtonColor, Record<string, RuleSet<object>>> = {
  primary: {
    backgroundColor: css`
      ${specific('button', 'primary-color')}
    `,
    hoverColor: css`
      ${specific('button', 'primary-color-hover')}
    `,
    color: css`
      ${specific('button', 'primary-font-color')}
    `,
    ringColor: css`
      ${specific('button', 'primary-ring-color')}
    `,
  },
  secondary: {
    backgroundColor: css`
      ${specific('button', 'secondary-color')}
    `,
    hoverColor: css`
      ${specific('button', 'secondary-color-hover')}
    `,
    color: css`
      ${specific('button', 'secondary-font-color')}
    `,
    ringColor: css`
      ${specific('button', 'secondary-ring-color')}
    `,
  },
  'primary-alt': {
    backgroundColor: css`
      ${specific('button', 'primary-color-alt')}
    `,
    hoverColor: css`
      ${specific('button', 'primary-color-alt-hover')}
    `,
    color: css`
      ${specific('button', 'primary-font-color')}
    `,
    ringColor: css`
      ${specific('button', 'primary-ring-color')}
    `,
  },
  'secondary-alt': {
    backgroundColor: css`
      ${specific('button', 'secondary-color-alt')}
    `,
    hoverColor: css`
      ${specific('button', 'secondary-color-alt-hover')}
    `,
    color: css`
      ${specific('button', 'secondary-font-color')}
    `,
    ringColor: css`
      ${specific('button', 'secondary-ring-alt-color')}
    `,
  },
  white: {
    backgroundColor: css`
      ${color('white')}
    `,
    hoverColor: css`
      ${color('white')}
    `,
    color: css`
      ${color('black')}
    `,
    ringColor: css`
      ${color('ghost')}
    `,
  },
  black: {
    backgroundColor: css`
      ${color('black')}
    `,
    hoverColor: css`
      ${color('black')}
    `,
    color: css`
      ${color('white')}
    `,
    ringColor: css`
      ${color('whisper-transparent-200')}
    `,
  },
  whisper: {
    backgroundColor: css`
      ${specific('button', 'whisper-color')}
    `,
    hoverColor: css`
      ${specific('button', 'whisper-color-hover')}
    `,
    color: css`
      ${specific('button', 'whisper-font-color')}
    `,
    ringColor: css`
      ${color('whisper-transparent-200')}
    `,
  },
  'whisper-alt': {
    backgroundColor: css`
      ${specific('button', 'whisper-color-alt')}
    `,
    hoverColor: css`
      ${specific('button', 'whisper-color-hover-alt')}
    `,
    color: css`
      ${specific('button', 'whisper-font-color-alt')}
    `,
    ringColor: css`
      ${color('whisper-transparent-200')}
    `,
  },
};

const negativeMargins = {
  before: css`
    margin-left: calc(-1 * ${spacer(125)});
  `,
  after: css`
    margin-right: calc(-1 * ${spacer(125)});
  `,
};

const iconPositions = {
  before: css`
    flex-direction: row-reverse;
  `,
  after: css`
    flex-direction: row;
  `,
};

const getHoverStyling = (colorProp: ButtonColor) => css`
  &:hover {
    background-color: ${buttonColors[colorProp]?.hoverColor};
    border-color: ${buttonColors[colorProp]?.hoverColor};
    color: ${buttonColors[colorProp]?.color};
    fill: ${buttonColors[colorProp]?.color};
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: 0 0 0 2px ${buttonColors[colorProp]?.ringColor};
  }
`;

export const StyledButtonIcon = styled(Icon)`
  display: flex;
`;

export interface StyledButtonProps {
  color: ButtonColor;
  disabled?: boolean;
  fontWeight?: FontWeight;
  fullwidth?: boolean;
  iconPosition?: IconPosition;
  negativeMargin?: NegativeMargin;
  outlined?: boolean;
  rounded?: boolean;
  size?: ButtonSize;
  text?: boolean;
}

export default styled.button<StyledButtonProps>`
  color: inherit;
  border: none;
  transition: all 0.2s ease-in-out;
  display: flex;
  gap: ${spacer('50')};
  align-items: center;
  justify-content: center;
  width: fit-content;
  border-radius: ${variable('border-radius')};
  font-weight: ${({ fontWeight }) => fontWeight};
  white-space: pre-line;

  ${({ color: colorProp, negativeMargin, text }) =>
    text &&
    css`
      && {
        // && -> Puts the className first making it higher ranked/prioritized
        background-color: transparent;
        border-color: transparent;
        color: black;

        ${colorProp && getHoverStyling(colorProp)}

        ${negativeMargin && negativeMargins[negativeMargin]}
      }
    `}

  ${({ color: colorProp }) =>
    css`
      &,
      &:link,
      &:visited {
        background-color: ${buttonColors[colorProp]?.backgroundColor};
        border-color: ${buttonColors[colorProp]?.backgroundColor};
        color: ${buttonColors[colorProp]?.color};
        fill: ${buttonColors[colorProp]?.color};
      }

      ${getHoverStyling(colorProp)}
    `}

  ${({ color: colorProp, outlined }) =>
    outlined &&
    css`
      &,
      &:link,
      &:visited {
        background-color: transparent;
        border: 1px solid ${buttonColors[colorProp]?.backgroundColor};
        color: ${buttonColors[colorProp]?.backgroundColor};
        fill: ${buttonColors[colorProp]?.backgroundColor};
      }

      &:active,
      &:focus,
      &:hover {
        background-color: ${buttonColors[colorProp]?.backgroundColor};
        border-color: transparent;
        color: ${buttonColors[colorProp]?.color};
        fill: ${buttonColors[colorProp]?.color};
      }
    `}

  ${({ rounded }) =>
    rounded &&
    css`
      border-radius: 40px;
    `}

  ${({ fullwidth }) =>
    fullwidth &&
    css`
      width: 100%;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.65;
      pointer-events: none;
    `}

  ${({ iconPosition }) => iconPosition && iconPositions[iconPosition]}

  ${({ size = '' }) => {
    const stringSize = buttonSizes[size];

    if (stringSize) return stringSize;

    if (isNumericValue(size)) {
      return css`
        padding: ${spacer(size)} ${spacer(size)};
      `;
    }

    return null;
  }}
`;
