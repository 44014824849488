import { GenericIcon } from 'components/Icon/Icon';
import { Icon, ICON_STYLE_DEFAULT } from 'constants/icon';
import { forwardRef, ReactNode } from 'react';
import { ButtonTypeAttribute } from 'types/Button';
import { Color } from 'types/Color';
import { ButtonColor, FlipValue, IconStyle, Spacer } from '../../constants/styling';
import Loader from '../Layout/Loader/Loader';
import StyledButton, { StyledButtonProps } from './Button.styled';

/*
 *  Examples:
 *  If color = primary && outlined = false -> spinner should be black
 *  If color = primary && outlined = true -> spinner should be primary
 */
const loaderColorMapper: Record<string, Color> = {
  primary: 'white',
  'primary-outlined': 'primary-300',
  secondary: 'white',
  'secondary-outlined': 'secondary-400',
  whisper: 'whisper-100',
  'whisper-outlined': 'whisper-200',
  white: 'white',
};

export interface ButtonProps extends Omit<StyledButtonProps, 'color'> {
  children?: ReactNode;
  className?: string;
  color?: ButtonColor;
  flipIcon?: FlipValue;
  icon?: Icon;
  iconSize?: Spacer;
  iconStyling?: IconStyle;
  id?: string;
  isCustomIcon?: boolean;
  label?: string;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  tabIndex?: number;
  type?: ButtonTypeAttribute;
}

/**
 * @deprecated This component is deprecated and should not be used anymore.
 * Please use Button from the ui folder instead.
 */
const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      color = 'primary',
      disabled,
      flipIcon,
      fontWeight = 500,
      fullwidth,
      icon,
      iconPosition = 'after',
      iconSize = 100,
      iconStyling = ICON_STYLE_DEFAULT,
      id,
      isCustomIcon,
      loading,
      negativeMargin,
      onClick,
      outlined,
      rounded = true,
      size = 'medium',
      tabIndex,
      text = false,
      type = 'button',
      ...props
    }: ButtonProps,
    ref,
  ) => {
    const iconProps = {
      flip: flipIcon,
      iconStyling,
      size: iconSize,
    };

    return (
      <StyledButton
        className={className}
        color={color}
        disabled={disabled || loading}
        fontWeight={fontWeight}
        fullwidth={fullwidth}
        iconPosition={iconPosition}
        id={id}
        negativeMargin={negativeMargin}
        onClick={onClick}
        outlined={outlined}
        rounded={rounded}
        size={size}
        tabIndex={tabIndex}
        text={text}
        type={type}
        ref={ref}
        {...props}
      >
        {children}
        {icon && !loading && <GenericIcon type={isCustomIcon ? 'custom' : 'fa'} name={icon} {...iconProps} />}
        <Loader
          color={loaderColorMapper[outlined ? `${color}-outlined` : color]}
          position="relative"
          show={loading}
          size={iconSize}
        />
      </StyledButton>
    );
  },
);

Button.displayName = 'Button';

export default Button;
