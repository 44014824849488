import useRouter from 'hooks/useRouter';
import { MouseEvent, ReactNode } from 'react';
import { UrlObject } from 'url';
import { cn } from 'utils/cn';
import { isExternalUrl, prefixWithLocale } from '../../utils/urlUtil';
import { StyledLink, StyledLinkProps } from './Link.styled';

export type Url = string | UrlObject;

interface LinkProps extends Omit<StyledLinkProps, 'href'> {
  children?: ReactNode;
  className?: string;
  id?: string;
  newTab?: boolean;
  preventDefault?: boolean;
  replaceUrl?: boolean;
  scroll?: boolean;
  shallow?: boolean;
  url?: Url;
}

const Link = ({
  children,
  className,
  id,
  newTab,
  onClick,
  preventDefault,
  replaceUrl,
  scroll = true,
  shallow,
  underlined,
  url,
  ...styledProps
}: LinkProps) => {
  const { locale } = useRouter();

  if (!url) return <>{children}</>;

  const isUrlObject = typeof url === 'object';
  const initialUrl = isUrlObject ? (url?.pathname ?? '') : url;

  const isExternal = isExternalUrl(initialUrl);

  const toUseUrl = isExternal ? initialUrl : prefixWithLocale(initialUrl, locale);

  const href: Url = isUrlObject ? { ...url, pathname: toUseUrl } : toUseUrl;

  const anchorProps =
    (newTab && {
      href: toUseUrl,
      rel: 'noopener noreferrer',
      target: '_blank',
    }) ||
    (isExternal && { href: toUseUrl }) ||
    {};

  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (preventDefault) event.preventDefault();
    onClick?.(event);
  };

  return (
    <StyledLink
      id={id}
      className={cn('focus-border ', className)}
      href={href || '/'}
      prefetch={false}
      replace={replaceUrl}
      scroll={scroll}
      shallow={shallow}
      onClick={handleClick}
      underlined={underlined}
      {...styledProps}
      {...anchorProps}
    >
      {children}
    </StyledLink>
  );
};

export default Link;
