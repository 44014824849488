import Link, { LinkProps } from 'next/link';
import styled from 'styled-components';
import { color, specific } from 'theme';
import { Color } from 'types/Color';
export interface StyledLinkProps extends LinkProps {
  hoverTextColor?: Color;
  textColor?: Color;
  underlined?: boolean;
}

export const StyledLink = styled(Link)<StyledLinkProps>`
  &,
  &:link,
  &:visited {
    color: ${({ textColor }) => (textColor ? color(textColor) : specific('link', 'color'))};

    &.richtext-content {
      color: ${specific('link', 'color--rich-text')};
    }
  }

  &.active,
  &:active,
  &:focus,
  &:hover {
    > {
      color: ${({ hoverTextColor }) => (hoverTextColor ? color(hoverTextColor) : specific('link', 'hover-color'))};
    }

    &.richtext-content {
      color: ${specific('link', 'hover-color--rich-text')};
    }
  }
  text-decoration: ${({ underlined }) => (underlined ? 'underline' : 'none')};
`;
