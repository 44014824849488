import { ReactNode } from 'react';
import { Color } from 'types/Color';
import {
  FontFamily,
  FontSize,
  FontTag,
  FontType,
  FontWeight,
  Spacer,
  TextAlign,
  TextDecoration,
  TextTransform,
  WhiteSpace,
  WordBreak,
} from '../../constants/styling';
import { removeWhiteSpaces } from '../../utils/textUtil';
import { StyledTypography } from './Typography.styled';

export interface TypographyProps extends React.HTMLAttributes<HTMLSpanElement> {
  as?: FontTag;
  children?: ReactNode;
  className?: string;
  color?: Color;
  fontFamily?: FontFamily;
  fontSize?: FontSize;
  fontWeight?: FontWeight;
  html?: string;
  lineHeight?: Spacer;
  marginBottom?: Spacer;
  marginTop?: Spacer;
  maxLines?: number;
  tag?: FontTag;
  textAlign?: TextAlign;
  textDecoration?: TextDecoration;
  textTransform?: TextTransform;
  type?: FontType;
  whiteSpace?: WhiteSpace;
  wordBreak?: WordBreak;
}

const Typography = ({
  children,
  className,
  color = 'black',
  fontFamily,
  fontSize,
  fontWeight,
  html,
  lineHeight,
  marginBottom,
  marginTop,
  maxLines,
  tag,
  textAlign,
  textDecoration,
  textTransform,
  type,
  whiteSpace,
  wordBreak,
  ...props
}: TypographyProps) => {
  const element = tag || type || 'span';

  const allProps = {
    as: element,
    className: removeWhiteSpaces(`${className || ''} typography`),
    color,
    fontFamily,
    fontSize,
    fontWeight,
    lineHeight,
    marginBottom,
    marginTop,
    maxLines,
    textAlign,
    textDecoration,
    textTransform,
    type,
    whiteSpace,
    wordBreak,
    ...props,
  };

  if (html) {
    return (
      <StyledTypography
        dangerouslySetInnerHTML={{
          __html: html,
        }}
        {...allProps}
      />
    );
  }

  if (!children) {
    return null;
  }

  return <StyledTypography {...allProps}>{children}</StyledTypography>;
};

export default Typography;
